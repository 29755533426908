.App {
  text-align: center;
  min-height: 100vh; /* 화면 높이 최소 100% */
  display: flex; /* Flexbox 활성화 */
  flex-direction: column; /* 자식 요소를 세로로 배치 */
  background-color: whitesmoke; /* 배경색 설정 */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.user-info {
  background-color: rgb(222, 214, 214);
  width: 100%; /* 부모 컨테이너 너비에 맞춤 */
  padding: 10px; /* 선택적, 내용 간격을 줄 수 있음 */
  box-sizing: border-box;
  margin-bottom: 45px;
}

.match-info {
  width: 100%; /* 부모 컨테이너 너비에 맞춤 */
  padding: 2px; /* 선택적, 내용 간격을 줄 수 있음 */
  box-sizing: border-box; /* padding을 포함하여 너비 계산 */
  display: flex;
  flex-direction: column; /* 세로 정렬 */
  align-items: center; /* 수평 중앙 정렬 */
}
.nav-row {
  justify-content: flex-start; /* 왼쪽 정렬 */
  width: calc(100% - 100px);
  margin-bottom: 25px; /* 하단 여백 */
  display: flex; /* 유연한 정렬을 위해 flex 사용 */
}

.user-detail {
  background-color: beige; /* 흰색 배경 */
  border: 2px solid beige;
  border-radius: 5px;
  margin-bottom: 12px;
  align-items: center;
  width: 99%;
}

.centered-img {
  display: block;
  margin: 0 auto;
  height: 350px; /* Optional if you want to define the height */
}

.max-rank {
  background-color: white; /* 흰색 배경 */
  border: 2px solid white;
  border-radius: 10px; /* 각 모서리 둥글게 (반경 10px) */
  padding: 10px; /* 내부 여백 */
  margin: 10px 5px; /* 상하 여백 */
}

.match-list {
  background-color: white; /* 흰색 배경 */
  border-radius: 10px; /* 각 모서리 둥글게 (반경 10px) */
  padding: 10px; /* 내부 여백 */
  margin: 10px 5px; /* 상하 여백 */
  width: 100%;
  cursor: pointer;
  height: 125px;
}

.match-detail {
  display: flex; /* 기본 flex 설정 */
  flex-direction: column; /* 자식 요소들을 세로로 정렬 */
  align-items: center; /* 자식 요소들을 가로 중앙 정렬 */
  justify-content: center; /* 자식 요소들이 세로로 중앙 정렬 */
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 5px;
  border: 1px solid black;
}

.col-left {
  width: 30%;
  padding: 10px; /* 여백 조정 가능 */
  border: 2px;
  border-radius: 10px;
  background-color: #f8f9fa; /* 선택적 배경색 */
}

.col-right {
  width: 70%;
  padding: 10px; /* 여백 조정 가능 */
  border: 2px;
  border-radius: 10px; /* 각 모서리 둥글게 (반경 10px) */
  background-color: white; /* 선택적 배경색 */
}
.container-sm {
  max-width: 1000px; /* 최대 너비 */
  width: 70%; /* 상대적 너비 */
  margin: 0 auto; /* 가운데 정렬 */
  margin-top: 30px;
}

.mini-button {
  width: 100%;
  height: 100%;
  background-color: blueviolet; /* 동적 색상 지원 */
  display: flex; /* 중앙 정렬을 위한 Flexbox */
  align-items: flex-end; /* 아래쪽으로 정렬 */
  justify-content: center; /* 가로로 중앙 정렬 */
  padding-bottom: 1px; /* 아래쪽에서 1px 띄우기 */
  font-size: 14px; /* 적절한 글자 크기 */
}

.controller-image {
  width: 1cm; /* 이미지 크기 */
  height: 1cm; /* 이미지 크기 */
  object-fit: cover; /* 이미지 비율을 맞추기 */
}

.map-container {
  position: relative;
  background-image: url('/public/img/map.png'); /* public 폴더 내 이미지 경로 */
  background-size: cover; /* 배경 이미지를 컨테이너에 맞게 조정 */
  background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
  width: 101%;
  height: 700px; /* div 높이 설정 */
}

.sub-container {
  display: flex;
  position: relative;
  background-color: aliceblue;
  width: 101%;
  height: 150px; /* 부모 컨테이너 높이 */
  border-radius: 10px;
  border: 1px solid #282c34;
  margin: 0 auto;

  /* 가로 및 세로 정렬 */
  flex-direction: row;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  flex-wrap: wrap; /* 넘칠 경우 줄바꿈 */
  gap: 10px; /* 요소 간격 */
}

.player-sub-container {
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex; /* 내부 아이템 정렬을 위한 flexbox */
  flex-direction: column; /* 세로 정렬 */
  justify-content: center; /* 세로 중앙 정렬 */
  align-items: center; /* 가로 중앙 정렬 */
  flex-shrink: 0; /* 크기 고정 */
}

.player-icon-container {
  position: absolute; /* 이미지 위에 올리기 위한 절대 위치 */
  transform: translate(-50% -50%);
  z-index: 1; /* 다른 요소보다 위로 올리기 */
  text-align: center;
}

.player-image {
  width: 1.5cm; /* 이미지 크기 */
  height: 1.5cm; /* 이미지 크기 */
  border-radius: 50%; /* 원형 이미지 */
  object-fit: cover; /* 이미지 비율을 맞추기 */
  background-color: white;
}

.spRating-circle {
  position: absolute;
  top: -0.5cm; /* 숫자 원을 이미지 위로 조금 올림 */
  left: 50%;
  transform: translateX(-50%); /* 가운데 정렬 */
  width: 0.6cm; /* 원형 크기 */
  height: 0.6cm; /* 원형 크기 */
  background-color: black; /* 원형 배경 색 */
  color: white; /* 숫자 색 */
  font-size: 0.3cm; /* 숫자 크기 */
  font-weight: bold;
  border-radius: 50%; /* 원형으로 만들기 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-box {
  display: flex;
  min-width: 2.5cm; /* 최소 너비 3cm */
  width: 100;
  margin-top: 0.1cm;
  border-radius: 8px;
  justify-content: space-between;
}

.info-left {
  flex: 1; /* flex-grow */
  background-color: #f0f0f0;
  padding: 0.1cm;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3cm;
  border-radius: 8px;
  border: 1px solid black;
}

.info-right {
  flex: 2; /* flex-grow */
  background-color: #e0e0e0;
  padding: 0.1cm;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3cm;
  border-radius: 8px;
  border: 1px solid black;
  white-space: nowrap; /* 텍스트가 한 줄로 표시되도록 설정 */
}
.sub-container {
  display: flex;
  flex-direction: column; /* 세로로 정렬 */
  align-items: center; /* 세로로 중앙 정렬 */
  margin: 20px 0;
  width: 100%; /* 부모 컨테이너 너비에 맞춤 */
}

.team-label {
  writing-mode: vertical-lr; /* 세로로 정렬, 오른쪽에서 왼쪽 방향 */
  text-orientation: upright; /* 글자를 직립 상태로 */
  font-size: 1.5rem; /* 글자 크기 */
  margin: 10px 0; /* 상하 간격 */
  text-align: center; /* 중앙 정렬 */
}

.main-content {
  flex: 1; /* 남은 공간을 차지 */
  padding-bottom: 110px; /* Footer 높이만큼 여백 */
}

.none-matchlist {
  flex: 1; /* 남은 공간을 차지 */
  height: 100%;
  margin-top: 60px;
}

.footer {
  position: fixed; /* 화면에 고정 */
  bottom: 0; /* 화면 하단에 위치 */
  width: 100%; /* 전체 너비 */
  background-color: #f8f9fa; /* 배경색 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 위쪽 그림자 효과 */
  text-align: center; /* 텍스트 가운데 정렬 */
  padding: 10px 0; /* 위아래 여백 */
  z-index: 1000; /* 다른 요소 위에 배치 */
}

.matchlist-statistics {
  margin-bottom: 20px;
  background-color: white;
  width: 90%; /* 부모 컨테이너 너비에 맞춤 */
  height: 240px;
  padding: 10px; /* 내부 여백 */
  display: flex; /* flexbox 활성화 */
  flex-direction: row; /* 가로로 정렬 */
  justify-content: center; /* 항목들 사이에 공간을 균등하게 배분 */
  align-items: center; /* 세로 가운데 정렬 */
  margin-left: 25px; /* 왼쪽 마진 추가 */
  margin-right: 25px; /* 오른쪽 마진 추가 */
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.statistics {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.shoot {
  margin-bottom: 8px;
  font-weight: 600;
}

.buildup-container {
  display: flex; /* Flexbox 활성화 */
  align-items: center; /* 세로 가운데 정렬 */
  justify-content: center; /* 가로 가운데 정렬 */
  position: relative; /* buildup 위치 조정 가능 */
  width: 100%; /* 필요에 따라 수정 */
  height: 100%; /* 필요에 따라 수정 */
}

/* buildup 요소 */
.buildup {
  z-index: 5;
  width: 29px;
  height: 18px;
  display: flex; /* Flexbox로 자식 콘텐츠 정렬 */
  align-items: center; /* 세로 정렬 */
  justify-content: center; /* 가로 정렬 */
  font-size: 15px;
  text-align: center;
  color: #4e545e;
  font-weight: 600;
  background: linear-gradient(
    140deg,
    rgb(2, 0, 36) 0%,
    rgb(216, 217, 220) 0%,
    rgb(184, 189, 202) 100%
  );
  border: 1.5px solid #a5a8ae;
  border-top-color: #d8dadc;
  border-left-color: #d8dadc;
  border-right-color: #a9aaae;
  box-sizing: border-box;
  margin-top: 7px;
}

.buildup-iron {
  color: #c5c8c9 !important;
  background: #51545a !important;
  background: linear-gradient(
    140deg,
    #51545a 0%,
    #51545a 0%,
    #42464d 100%
  ) !important;
  border: 1.5px solid #393a3c !important;
  border-top-color: #62676d !important;
  border-left-color: #62676d !important;
  border-right-color: #393a3c !important;
}

.buildup-bronze {
  color: #7e3f27 !important;
  background: #de946b !important;
  background: linear-gradient(
    140deg,
    #de946b 0%,
    #de946b 0%,
    #ad5f42 100%
  ) !important;
  border: 1.5px solid #864229 !important;
  border-top-color: #e4b7a2 !important;
  border-left-color: #e4b7a2 !important;
  border-right-color: #864229 !important;
}

.buildup-silver {
  color: #4e545e !important;
  background: #020024 !important;
  background: linear-gradient(
    140deg,
    rgb(2, 0, 36) 0%,
    rgb(216, 217, 220) 0%,
    rgb(184, 189, 202) 100%
  ) !important;
  border: 1.5px solid #a5a8ae !important;
  border-top-color: #d8dadc !important;
  border-left-color: #d8dadc !important;
  border-right-color: #a9aaae !important;
}

.buildup-gold {
  color: #695100 !important;
  background: #f9dd62 !important;
  background: linear-gradient(
    140deg,
    #f9dd62 0%,
    #f9dd62 0%,
    #dca908 100%
  ) !important;
  border: 1.5px solid #cda000 !important;
  border-top-color: #e9d36c !important;
  border-left-color: #e9d36c !important;
  border-right-color: #cda000 !important;
}

.playercard-static {
  display: flex;
  border: 2.5px solid black;
  border-radius: 10px;
  background-color: whitesmoke;
}

.playercard-static-row {
  margin-bottom: 10px;
}

.playercard-static-title {
  font-size: 18px;
  font-weight: 600;
  color: chocolate;
  margin-bottom: 7px;
}

.playercard-static-content {
  margin-left: 7px;
}

.playercard-static-content div {
  margin-bottom: 2px; /* content 간 간격 */
}

.playercard-static-content div:last-child {
  margin-bottom: 0; /* 마지막 content 간격 제거 */
}

.match-statistics {
  font-size: 20px;
}

.match-statistics div {
  margin-bottom: 7px; /* content 간 간격 */
}

.match-statistics div:last-child {
  margin-bottom: 0; /* 마지막 content 간격 제거 */
}

.match-statistics-content {
  color: midnightblue;
}
.match-statistics-middle {
  font-weight: bold;
  color: lightslategray;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center-position {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding-top: '200px';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
